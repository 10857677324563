import React, { Component } from "react";
import "./../Styles.css";
import Zoom from "react-reveal/Zoom";
import ReactPlayer from "react-player";
import Audio from "./Audio.js";
import Mute from "./Mute.js";

class Workscol extends Component {
  state = { videoLoaded: false, muted: true };

  // showVid = () => {
  //   this.setState({
  //     hovered: true,
  //   });
  // };
  // hideVid = () => {
  //   this.setState({
  //     hovered: false,
  //   });
  // };
  render() {
    let width = window.innerWidth;
    return (
      <>
        <Zoom fraction={width > 767 ? 0.6 : 0.9}>
          <div
            className={`col-md-6 text-center  col-works-home   `}
            style={this.props.style}
          >
            {this.props.hovered &&
            this.state.videoLoaded &&
            !this.state.muted ? (
              <Audio
                onMouseEnter={this.props.onMouseEnter}
                onMouseLeave={this.props.onMouseLeave}
                onClick={() => this.setState({ muted: true })}
              />
            ) : null}
            {this.props.hovered &&
            this.state.videoLoaded &&
            this.state.muted ? (
              <Mute
                onMouseEnter={this.props.onMouseEnter}
                onMouseLeave={this.props.onMouseLeave}
                onClick={() => this.setState({ muted: false })}
              />
            ) : null}
            <a
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none !important" }}
              href={this.props.src}
            >
              <div
                onMouseEnter={this.props.onMouseEnter}
                onMouseLeave={this.props.onMouseLeave}
                className={`iframe-overlay ${
                  this.props.hovered && "scale-iframe"
                }`}
              ></div>
            </a>
            <div
              className={` parent-iframe embed-responsive embed-responsive-16by9 ${
                this.props.hovered && "scale-iframe"
              }`}
              style={{ width: "100%", height: "100%", position: "relative" }}
            >
              {this.props.hovered && !this.state.videoLoaded ? (
                <div
                  className="spinner-border text-dark video-spinner"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              ) : null}

              <ReactPlayer
                muted={this.state.muted}
                onStart={() => this.setState({ videoLoaded: true })}
                url={this.props.src}
                playing={this.props.hovered ? true : false}
                height="100%"
                width="100%"
                volume={0.5}
              />
            </div>

            <h3 className="works-title">{this.props.name}</h3>
          </div>
        </Zoom>
      </>
    );
  }
}

export default Workscol;
