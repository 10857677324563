import React, { useEffect, useState, useRef } from "react";
// import { Fade } from "react-awesome-reveal";
import SVG from "react-inlinesvg";
import AOS from "aos";
// import api from "./../components/api.js";
import { InView } from "react-intersection-observer";

// import { Fade } from "react-awesome-reveal";

const ServiceColLarge = (props) => {
  const [hasAppeared, setHasAppeared] = useState(false);
  // const [refs, setRefs] = useState([]);

  const inputEl = useRef(null);
  // refs.push(inputEl);
  // const refPo = 0;
  let width = window.innerWidth;
  useEffect(() => {
    if(width < 767){
 AOS.init({
   // initialise with other settings
   duration: 1500,
   offset: 150,
   once: true,
 });
    }else{
       AOS.init({
         // initialise with other settings
         duration: 1500,
         offset: 220,
         once: true,
       });
    }
   
  },[width] );

  // useEffect(() => {
  //   if (!hasAppeared ) {
  //     document.addEventListener("aos:in", () => {
  //       setHasAppeared(true);
  //     });
  //   }
  // }, []);

  return (
    <>
      <InView
        threshold={0.9}
        triggerOnce={true}
        className="inview-div col-12 col-md-6 text-center  my-3  "
        as="div"
        onChange={(inView, entry) => inView && setHasAppeared(!hasAppeared)}
      >
        <div
          data-aos="fade-up"
          ref={inputEl}
          // onAnimationStart={() => setHasAppeared({ hasAppeared: true })}
          className={``}
        >
          <div className={`mt-5 ${hasAppeared && "parent-svg"}`}>
            <SVG src={`https://apiv2.admedia.agency/${props.icon}`} />
          </div>

          <div
            className={`mt-3 service-col-title ${
              hasAppeared && "show-title-service"
            }`}
          >
            {props.title}
          </div>
          <div
            className={`mt-2 mx-5 servicecol-descript text-center ${
              hasAppeared && "show-descript-service"
            }`}
          >
            {props.description}
          </div>
        </div>
      </InView>
    </>
  );
};



export default ServiceColLarge;
