import React, { useState } from "react";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";

const ServicesColumn = ({
  title,
  icon,
  margin,
  marginText,
  padding,
  animate,
}) => {
  const [hasAppeared, sethasAppeared] = useState(false);
  return (
    <Fade cascade={true} bottom onReveal={() => sethasAppeared(!hasAppeared)}>
      <div
        className={`col-6 col-md-3 col-lg-3 service-column-size ${padding} text-center `}
        style={{ overFlow: "hidden" }}
      >
        <Link className="services-link" to="/services">
          <div className={` ${hasAppeared ? "svg-col" : "no-svg"} ${margin}`}>
            {icon}
          </div>
        </Link>
        <Link className="services-link" to="/services">
          {hasAppeared && (
            <div className={`d-inline ${"svgs-title-home"} ${marginText}  `}>
              {title}
            </div>
          )}
        </Link>
      </div>
    </Fade>
  );
};

export default ServicesColumn;
