import React, { Component } from "react";
import api from "./api.js";
// import './Slider.scss'
import Slider from "react-slick";



class Slideshow extends Component {
  state = { logos: [] };

  componentDidMount() {
    const logos = async () => {
      const { data } = await api.get("/logos");
      this.setState({ logos: data.data.logos });
    };
    logos();
  }
  render() {
    
    const settings = {
      dots: false,
      variableWidth: 1,
      infinite: true,
      slidesToShow: 7,
      slidesToScroll: 1,
      autoplay: true,
      speed: 4000,
      
     autoplaySpeed: 0, 
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 560,
          settings: {
            slidesToShow: 4,
          },
        },
      ],
    };
    return (
      <>
        
        <div>
          <Slider {...settings}>
            {this.state.logos.map((el, index) => (
              <div className="slider-images-parent" key={index}>
                <img
                  className={`img-fluid slider-img`}
                  src={`https://apiv2.admedia.agency/${el.logo}`}
                  alt="slider-img"
                  // style={imgStyles}
                />
              </div>
            ))}
          </Slider>
        </div>
      </>
    );
  }
}

export default Slideshow;
