import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "./pages/Home";
import Services from "./pages/Services";
import MediaBuying from "./pages/mediaBuying";
import About from "./pages/About";
import Ourworks from "./pages/Ourworks";
import "./Styles.css";

class App extends Component {
  componentDidMount() {
    
   
  }

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/services" component={Services} />
          <Route exact path="/about" component={About} />
          <Route exact path="/ourworks" component={Ourworks} />
          <Route exact path="/mediabuying" component={MediaBuying} />
          <Route exact path="/" component={Home} />
        </Switch>
      </BrowserRouter>
    );
  }
}
export default App;
