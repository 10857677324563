import React, { Component } from "react";
import Navbar from "./../components/Navbar";
import Footer from "./../components/Footer";
import Project from "./../components/Project";
import Workcol from "./../components/Workscol";
import api from "./../components/api.js";
import { Helmet } from "react-helmet";
import ReactPaginate from "react-paginate";

const activeFrom = -250;

class Ourworks extends Component {
  constructor(props) {
    super(props);
    this.handlePageClick = this.handlePageClick.bind(this);

    this.state = {
      currentPage: 1,
      loading: true,
      loaderVisible: this.props.loaderVisible,
      vidNames: [],
      marginBod: false,
      works: [],
      worksTotal: 0,
      tags: [],
      filtered: false,
      filteredEl: [],
      selected: false,
      active_id: null,
      selectedButton: false,
      isActive: false,
      isOpen: false,
      hoveredEl: [],
      ourworks: [],
      videoLoaded: false,
    };

    this.ref = React.createRef();
  }

  fetchWorks = async () => {
    const { data } = await api.get(
      "/works?limit=10&page=" + this.state.currentPage
    );

    const works = data.data.work.map((work) => {
      return {
        ...work,
        isHovered: false,
      };
    });

    this.setState({
      worksTotal: data.total,
      works,
      vidNames: works.map((work) => work.name).join(", "),
    });
  };
  tagsParent = async (id, page, fronButton) => {
    if (fronButton) {
      this.setState({ currentPage: 1 }, async () => {
        const { data } = await api.get(
          "/works/tag/" + id + "?limit=10&page=" + page
        );
        this.setState({
          filtered: true,
          selected: true,
          works: data.data.works,
          worksTotal: data.total,
        });
      });
    }
    const { data } = await api.get(
      "/works/tag/" + id + "?limit=10&page=" + page
    );
    this.setState({
      filtered: true,
      selected: true,
      works: data.data.works,
      worksTotal: data.total,
    });

    this.handleProductSelect(id);
  };
  removeSelected = () => {
    this.setState({ active_id: null, filtered: false });
  };

  addMargin = () => {
    this.setState({ marginBod: true });
  };
  removeMargin = () => {
    this.setState({ marginBod: false });
  };
  openProject = (event) => {
    event.preventDefault();
    this.setState({ isOpen: true });
  };

  closeProject = () => {
    this.setState({ isOpen: false });
  };

  handleScroll = () => {
    const { top } = this.ref.current.getBoundingClientRect();

    if (top < activeFrom) {
      this.setState({ isActive: true });
    }
  };
  toggleHover = (id) => {
    this.setState({
      hoveredEl: id,
    });
  };
  endHover = () => {
    this.setState({ hoveredEl: [] });
  };
  // endHover = () => {
  //   if (this.state.divHovered) {
  //     this.setState({ divHovered: false });
  //   }
  // };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    if (this.state.loaderVisible) {
      this.setState({ loading: false });
    } else {
      setTimeout(() => this.setState({ loading: false }), 2500);
    }

    window.onbeforeunload = (e) => {
      window.scrollTo(0, 0);
    };
    window.scrollTo(0, 0);

    this.fetchWorks();
    const tags = async () => {
      const { data } = await api.get("/tags");
      this.setState({ tags: data.data.tags });
    };
    tags();

    const ourworks = async () => {
      const { data } = await api.get("/ourworks");
      this.setState({
        ourworks: data.data,
      });
    };
    ourworks();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentPage !== this.state.currentPage) {
      if (this.state.filtered) {
        this.tagsParent(this.state.active_id, this.state.currentPage);
      } else this.fetchWorks(); // Re-fetch works when currentPage changes
    }
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  handleProductSelect = (_id) => {
    // const { borderColor } = this.state;
    const { selected } = this.state;
    let newButton =
      selected === false ? "works-buttons px-5" : "selected-button px-5";
    this.setState({
      selected: newButton,
      active_id: _id,
      selectedButton: true,
    });
  };

  async handlePageClick({ selected: selectedPage }) {
    this.setState({ currentPage: selectedPage + 1 });
  }

  render() {
    let width = window.innerWidth;
    let delay = 1.5;

    return (
      <>
        <Helmet>
          <title>Our works</title>
          <meta
            name="description"
            content="We at AD Media Agency offer a variety of different services for our valued clients such as 3D Animation, TVC, VFX, Events, Product  Visualization, Corparate."
          />
          <meta name="keywords" content={this.state.vidNames} />
          <meta name="author" content="AD Media Agency" />
        </Helmet>
        <div ref={this.ref}>
          <section>
            <div className="works-vid-parent">
              <div className="vid-layer"></div>
              {this.state.ourworks.length > 0 && (
                <video
                  autoPlay
                  loop
                  muted
                  playsInline
                  onCanPlayThrough={() => this.setState({ videoLoaded: true })}
                  className="home-video"
                >
                  <source
                    src={`https://apiv2.admedia.agency/${this.state.ourworks[0].video}`}
                    type="video/mp4"
                  />
                </video>
              )}
            </div>
            <Navbar
              isOpen={this.openProject}
              isMargined={this.addMargin}
              removeMargin={this.removeMargin}
            />
            <div
              className={` no-margined ${
                this.state.marginBod ? "margined-body" : null
              }`}
            ></div>
            {!this.state.videoLoaded ? (
              <div className={`loader`}>
                <div className="spinner">
                  <div className="spinner-border text-light" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            ) : null}
            {this.state.isOpen ? (
              <Project show={true} closeProject={this.closeProject}></Project>
            ) : (
              <Project show={false} />
            )}
            <div className="container ">
              <div className="row title-text-row mt-5 mt-md-0">
                <div className="col-12">
                  {this.state.videoLoaded && !this.state.loaderVisible ? (
                    <h1 className="intro-text" style={{ color: "#DAEDF2" }}>
                      {this.state.ourworks.length > 0 &&
                        this.state.ourworks[0].title}
                    </h1>
                  ) : null}
                </div>
              </div>
            </div>
          </section>
        </div>
        <section
          className={`our-works-section2 ${
            !this.state.videoLoaded && "limit-scroll"
          }`}
        >
          <div className="container ">
            <div className="row ">
              <div className=" col-md-12 d-md-flex justify-content-md-center mt-5 ">
                <button
                  key={"all"}
                  onClick={() => {
                    this.setState({ filtered: false, currentPage: 1 });
                    this.handleProductSelect("all");
                    this.fetchWorks();
                  }}
                  className={` ${
                    null === this.state.active_id ||
                    "all" === this.state.active_id
                      ? "selected-button"
                      : "works-buttons"
                  } animate-button `}
                  style={{ animationDelay: "2s" }}
                >
                  All
                </button>
                {this.state.tags.length > 0 &&
                  width < 767 &&
                  this.state.tags.map((el) => {
                    return (
                      <button
                        style={{ animationDelay: `${(delay += 0.2)}s` }}
                        key={el._id}
                        onClick={() => {
                          this.tagsParent(el._id, 1, true);
                        }}
                        className={` ${
                          el._id === this.state.active_id
                            ? "selected-button"
                            : "works-buttons"
                        } animate-button`}
                      >
                        {el.tag}
                      </button>
                    );
                  })}
                {this.state.tags.length > 0 &&
                  width > 767 &&
                  this.state.tags.slice(0, 3).map((el) => {
                    return (
                      <button
                        style={{ animationDelay: `${(delay += 0.2)}s` }}
                        key={el._id}
                        onClick={() => {
                          this.tagsParent(el._id, 1, true);
                        }}
                        className={` ${
                          el._id === this.state.active_id
                            ? "selected-button"
                            : "works-buttons"
                        } animate-button`}
                      >
                        {el.tag}
                      </button>
                    );
                  })}
              </div>
            </div>
            <div className="row ">
              <div className="col-md-11 d-md-flex justify-content-md-center mt-1">
                {this.state.tags.length > 0 &&
                  width > 767 &&
                  this.state.tags.slice(4, 8).map((el) => {
                    return (
                      <button
                        style={{ animationDelay: `${(delay += 0.2)}s` }}
                        key={el._id}
                        onClick={() => {
                          this.tagsParent(el._id, 1, true);
                        }}
                        className={` ${
                          el._id === this.state.active_id
                            ? "selected-button"
                            : "works-buttons"
                        } animate-button`}
                      >
                        {el.tag}
                      </button>
                    );
                  })}
              </div>
            </div>
            <div className="row ">
              <div className="col-md-11 d-md-flex justify-content-md-center mt-1">
                {this.state.tags.length > 0 &&
                  width > 767 &&
                  this.state.tags.slice(8).map((el) => {
                    return (
                      <button
                        style={{ animationDelay: `${(delay += 0.2)}s` }}
                        key={el._id}
                        onClick={() => {
                          this.tagsParent(el._id, 1, true);
                        }}
                        className={` ${
                          el._id === this.state.active_id
                            ? "selected-button"
                            : "works-buttons"
                        } animate-button`}
                      >
                        {el.tag}
                      </button>
                    );
                  })}
              </div>
            </div>

            <div
              className={`row mt-5 d-flex  `}
              style={{ paddingBottom: "3rem" }}
            >
              {this.state.works.map((w, index) => {
                return (
                  <Workcol
                    onMouseEnter={() => this.toggleHover(w._id)}
                    onMouseLeave={this.endHover}
                    key={w._id}
                    name={
                      this.state.hoveredEl.length > 0 &&
                      w._id === this.state.hoveredEl
                        ? null
                        : w.name
                    }
                    hovered={
                      this.state.hoveredEl.length > 0 &&
                      w._id === this.state.hoveredEl
                        ? true
                        : false
                    }
                    src={w.link}
                  />
                );
              })}
              <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                onPageChange={this.handlePageClick}
                forcePage={this.state.currentPage - 1}
                pageRangeDisplayed={10}
                pageCount={this.state.worksTotal / 10}
                containerClassName="pagination"
                activeLinkClassName="pagination-active"
                activeClassName="pagination-active"
                pageClassName="pagination-page-item"
                previousLabel="<"
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
        </section>
        <section
          className={`footer-div ${!this.state.videoLoaded && "limit-scroll"}`}
        >
          <Footer></Footer>
        </section>
      </>
    );
  }
}

export default Ourworks;
