import React, { Component } from "react";
import Navbar from "./../components/Navbar";
import Footer from "./../components/Footer";
// import Slidercard from "./../components/Slidercard";
// import Carousel from "react-multi-carousel";
import api from "./../components/api.js";
import { Fade } from "react-awesome-reveal";
// import Zoom from "react-reveal/Zoom";
import "react-multi-carousel/lib/styles.css";
import "./../Styles.css";
import CountTo from "react-count-to";
import AOS from "aos";
import { Helmet } from "react-helmet";

const changeColor = 100;

// const responsive = {
//   desktop: {
//     breakpoint: { max: 3000, min: 1024 },
//     items: 1,
//   },
//   tablet: {
//     breakpoint: { max: 1024, min: 464 },
//     items: 1,
//   },
//   mobile: {
//     breakpoint: { max: 464, min: 0 },
//     items: 1,
//     slidesToSlide: 1, // optional, default to 1.
//   },
// };

class About extends Component {
  state = {
    loading: true,
    loaderVisible: this.props.loaderVisible,
    about: [],
    marginBod: false,
    isActive: false,
    testemonies: [],
    colorChanged: false,
    countIsDone: false,
    yearDone: false,
    imgLoaded: false,
    firstSectionFinished: false,
    x: 1,
  };

  addMargin = () => {
    this.setState({ marginBod: true });
  };
  removeMargin = () => {
    this.setState({ marginBod: false });
  };
  handleScroll = () => {
    const { top } = this.ref.current.getBoundingClientRect();

    if (top === changeColor || top < changeColor) {
      this.setState({ colorChanged: true });
    }
    if (top > changeColor) {
      this.setState({ colorChanged: false });
    }
  };
  componentDidMount() {
    AOS.init({
      // initialise with other settings
      duration: 1500,
      offset: 250,
    });

    setTimeout(
      () =>
        this.setState({
          loading: false,
        }),
      2500
    );
    window.onbeforeunload = (e) => {
      window.scrollTo(0, 0);
    };
    window.scrollTo(0, 0);

    const testemonies = async () => {
      const { data } = await api.get("/testemonies");
      this.setState({
        testemonies: data.data,
      });
    };
    testemonies();
    const about = async () => {
      const { data } = await api.get("/about");
      this.setState({
        about: data.data.about,
      });
    };
    about();
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    this.setState({ colorChanged: false });
    window.removeEventListener("scroll", this.handleScroll);
  }
  // componentDidUpdate() {
  //   console.log("updated");
  // }

  render() {
    this.ref = React.createRef();
    let width = window.innerWidth;
    return (
      <>
        <Helmet>
          <title>About Us</title>
          <meta
            name="description"
            content="AD Media Agency is a Kurdistan and Iraqi-based company composed of seven departments: Branding, Corporate Social Responsibility, Video Production, AD Sound Studios, Media Buying, Digital Media, and Monitoring."
          />
          <meta
            name="keywords"
            content="AD Media Agency, Advertising Agency, Kurdish Advertising, Iraq Advertising, Kurdistan and Iraqi-based Advertising Company, Marketing, Brand, Graphic Design, Ad, Advertisements, Marketing Plan, Advertise, Advertising Agency, Banner, Banner Ads"
          />
          <meta name="author" content="AD Media Agency" />
        </Helmet>
        <section className="about-section-1 ">
          {this.state.loading && (
            <div className={`loader`}>
              <div className="spinner">
                <div className="spinner-border text-light" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          )}
          <Navbar
            color={this.state.colorChanged}
            hideButton="d-none"
            isMargined={this.addMargin}
            removeMargin={this.removeMargin}
          />
          <div
            className={`  ${
              this.state.marginBod ? "margined-body" : "no-margined"
            }`}
          ></div>
          <img
            className="about-img img-fluid"
            // onLoad={() => this.setState({ imgLoaded: true })}
            alt="About Admedia Agency"
            src={`https://apiv2.admedia.agency/${
              this.state.about.length > 0 && this.state.about[0].img
            }`}
          />
          <div className="container">
            <div className="row">
              <div className="col-12 mt-5 col-md-7 mb-4 mb-md-0  text-white d-md-flex align-items-left">
                <div className="  p-position-about">
                  {width > 767 && !this.state.loading && (
                    <h1
                      onAnimationStart={() =>
                        this.setState({ imgLoaded: true })
                      }
                      className="h-about-high mt-5"
                    >
                      {this.state.about.length > 0 && this.state.about[0].idea}
                    </h1>
                  )}
                  {!this.state.loading && width > 767 && (
                    <h3 className="h-about-low dot-div">
                      {this.state.about.length > 0 &&
                        this.state.about[0].ideaParagraph}
                    </h3>
                  )}
                </div>
              </div>
            </div>
            <div className="row numbers-row d-flex justify-content-between align-items-end">
              <div className="col-md-3 numbers-col text-center text-white my-3 my-md-0">
                {!this.state.loading && (
                  <h1
                    style={{ animationDelay: "1s" }}
                    className="h-about-high-num"
                  >
                    <CountTo
                      onComplete={() => this.setState({ yearDone: true })}
                      to={
                        this.state.about.length > 0 &&
                        this.state.about[0].yearFounded
                      }
                      speed={2000}
                    />
                  </h1>
                )}
                {!this.state.loading && (
                  <h4
                    style={{ animationDelay: "1s" }}
                    className="h-about-low-num"
                  >
                    FOUNDED
                  </h4>
                )}
                {!this.state.loading && (
                  <div
                    style={{ animationDelay: "200ms" }}
                    className="line-border"
                  ></div>
                )}
              </div>

              <div className="col-md-3 numbers-col text-center text-white my-3 my-md-0">
                {!this.state.loading && (
                  <h1
                    className="h-about-high-num"
                    style={{ animationDelay: "2s" }}
                  >
                    <CountTo
                      onComplete={() => this.setState({ countIsDone: true })}
                      to={
                        this.state.about.length > 0 &&
                        this.state.about[0].clients
                      }
                      delay={1}
                      speed={3000}
                    />
                    +
                  </h1>
                )}
                {!this.state.loading && (
                  <h4
                    style={{ animationDelay: "2s" }}
                    className="h-about-low-num"
                  >
                    PROJECTS
                  </h4>
                )}
                {!this.state.loading && (
                  <span
                    style={{ animationDelay: "1s" }}
                    className="line-border"
                  ></span>
                )}
              </div>

              <div className="col-md-3 numbers-col text-center text-white my-3 my-md-0">
                {!this.state.loading && (
                  <h1
                    style={{ animationDelay: "2.8s" }}
                    className="h-about-high-num"
                  >
                    {this.state.countIsDone && (
                      <CountTo
                        delay={1}
                        to={
                          this.state.about.length > 0 &&
                          this.state.about[0].experience
                        }
                        speed={1000}
                      />
                    )}
                  </h1>
                )}
                {!this.state.loading && (
                  <h4
                    style={{ animationDelay: "2.8s" }}
                    className="h-about-low-num"
                    onAnimationEnd={() =>
                      this.setState({ firstSectionFinished: true })
                    }
                  >
                    COUNTRIES SERVED
                  </h4>
                )}
                {!this.state.loading && (
                  <div
                    style={{ animationDelay: "1.8s" }}
                    className="line-border"
                  ></div>
                )}
              </div>
            </div>
          </div>
        </section>

        <section
          ref={this.ref}
          className={`about-section-2 ${this.state.loading && "limit-scroll"}`}
        >
          <div className="container">
            <div className="row my-5">
              <div className="col-md-4 my-3 my-md-0">
                <Fade direction="up" triggerOnce={true} fraction={0.9}>
                  <h3 className="h-about-high-2">
                    {" "}
                    {this.state.about.length > 0 &&
                      this.state.about[0].aboutAdmedia}
                  </h3>
                </Fade>

                <Fade
                  direction="up"
                  triggerOnce={true}
                  fraction={0.9}
                  delay={300}
                >
                  <h6 className="h-about-low-2">
                    {" "}
                    {this.state.about.length > 0 &&
                      this.state.about[0].whoarewe}
                  </h6>
                </Fade>
              </div>
              <div className="col-md-8">
                <Fade
                  direction="right"
                  triggerOnce={true}
                  fraction={0.9}
                  delay={400}
                >
                  <p className={` whoarewe-p `}>
                    {this.state.about.length > 0 &&
                      this.state.about[0].whoareweParagraphOne}
                  </p>
                  <br />
                </Fade>
                <Fade
                  direction="right"
                  triggerOnce={true}
                  fraction={0.9}
                  delay={600}
                >
                  <p className={` whoarewe-p `}>
                    {this.state.about.length > 0 &&
                      this.state.about[0].whoareweParagraphTwo}
                  </p>
                  <br />
                </Fade>
                <Fade
                  direction="right"
                  triggerOnce={true}
                  fraction={0.9}
                  delay={800}
                >
                  <p className={` whoarewe-p `}>
                    {this.state.about.length > 0 &&
                      this.state.about[0].whoareweParagraphThird}
                  </p>
                  <br />
                </Fade>
              </div>
            </div>
            {/* <div className="row my-md-5">
              <div className="col-12 text-center text-md-left col-md-3 col-lg-4 mt-md-5 ">
                <Fade
                  direction="up"
                  triggerOnce={true}
                  fraction={0.9}
                  delay={900}
                >
                  <h3 className="h-about-high-2 mt-3">What our clients</h3>
                </Fade>
                <Fade
                  direction="up"
                  triggerOnce={true}
                  fraction={0.9}
                  delay={1100}
                >
                  <h6 className="h-about-low-2 mt-3">Say about us</h6>
                </Fade>
              </div>

              <div className="col-md-9 col-lg-8">
                <Fade triggerOnce={true} fraction={0.9} delay={1200}>
                  <Carousel
                    swipeable={true}
                    draggable={true}
                    arrows={true}
                    showDots={false}
                    responsive={responsive}
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={3000}
                    keyBoardControl={true}
                    transitionDuration={10}
                    containerClass="carousel-container"
                    deviceType={this.props.deviceType}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                  >
                    {this.state.testemonies.length > 0 &&
                      this.state.testemonies.map((el) => {
                        return (
                          <Slidercard
                            key={el._id}
                            name={el.name}
                            position={el.position}
                            text={el.text}
                            src={el.avatar}
                          />
                        );
                      })}
                  </Carousel>
                </Fade>
              </div>
            </div>*/}
          </div>
        </section>
        <section
          className={`footer-div ${this.state.loading && "limit-scroll"}`}
        >
          <Footer></Footer>
        </section>
      </>
    );
  }
}

export default About;
