import React, { useState, useEffect } from "react";

import "./../Styles.css";
import "./../slick.css";

const TitleSliderMobile = (props) => {
  
  const [index, SetIndex] = useState(0);
  const [initiate, setInitiate] = useState(false);

  useEffect(() => {
    const lastIndex = props.data.length - 1;
    if (initiate) {
      if (index < 0) {
        SetIndex(lastIndex);
      }
      if (index > lastIndex) {
        SetIndex(0);
      }
    }
  }, [index, initiate, props.data]);

  useEffect(() => {
    if (initiate) {
      let slider = setInterval(() => {
        SetIndex(index + 1);
      }, 9000);
      return () => clearInterval(slider);
    }
  }, [index, initiate]);
  useEffect(() => {
    !props.show && setTimeout(() => setInitiate(true), 1000);
  });

  return (
    <>
      {props.data.length > 0 &&
        props.data.map((el, elIndex) => {
          let position = "next-slide";

          if (index === elIndex) {
            position = "current-slide";
          }
          if (
            elIndex === index - 1 ||
            (index === 0 && elIndex === props.data.length - 1)
          ) {
            position = "last-slide";
          }
          let words = el.title.split(" ");
          // let delay = 1;

          //   console.log(words)

          return (
            <>
              {elIndex === index && (
                <div
                  className={`word-title-parent ${position} ${
                    index === elIndex ? "add-delay" : null
                  }`}
                >
                  {words.slice(0,4).map((word, i) => {
                    return (
                      <div key={i} className={`word-div-mobile`}>
                        {initiate && word}
                        <p className="mobile-paragraph"></p>
                      </div>
                    );
                  })}
                  {words.slice(4).map((word, i) => {
                    return (
                      <div key={i} className={`word-div-mobile`}>
                        <p className="mobile-paragraph">{initiate && word}</p>
                      </div>
                    );
                  })}
                </div>
              )}
            </>
          );
        })}
    </>
  );
};

export default TitleSliderMobile;
