import React from "react";
// import styled from "styled-components";
// import { Link } from "react-router-dom";
// import close from "./../svgs/close.png";
// import {motion} from 'framer-motion'

const Sidebar = (props) => {
  return (
    // <SideDiv>
    <>
      <div className={`row ${props.toggle}`}>
        <div className=" ml-1 container ">
          <div className="row mt-3">
            <div className="col-12 ml-3">
              <span className="close-span" aria-hidden="true">
                &times;
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-2 ">
              <nav className={`navbar  `}>
                <ul className={`navbar-nav menu-list ${props.state}`}>
                  <li className="nav-item">
                    <a href="/" className="nav-link">
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="/services" className="nav-link">
                      Services
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="/ourworks" className="nav-link">
                      Our Works
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="/mediabuying" className="nav-link">
                      Media Buying
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="/about" className="nav-link">
                      About
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
