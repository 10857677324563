import React from "react";
import "./../Styles.css";

const Mute = (props) => {
  return (
    <>
      <svg
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
        onClick={props.onClick}
        className="mute-svg"
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
        style={{ enableBackground: "new 0 0 512 512" }}
      >
        <g>
          <g>
            <path
              d="M102.538,150.919H0v210.162h102.538l199.695,133.13V17.789L102.538,150.919z M92.071,331.058H30.023V180.942h62.048
			V331.058z M272.209,438.112L122.094,338.035v-164.07L272.209,73.888V438.112z"
            />
          </g>
        </g>
        <g>
          <g>
            <polygon
              points="512,191.557 490.772,170.328 426.328,234.771 361.884,170.328 340.656,191.557 405.099,256 340.656,320.444 
			361.884,341.673 426.328,277.229 490.772,341.673 512,320.444 447.557,256 		"
            />
          </g>
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </svg>
    </>
  );
};

export default Mute;
