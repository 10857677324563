import React, { Component } from "react";
import Navbar from "./../components/Navbar";
import Footer from "./../components/Footer";
import Project from "./../components/Project";
import ServiceColLarge from "./../components/ServiceColLarge";
import api from "./../components/api.js";
import { Helmet } from "react-helmet";
// import Zoom from "react-reveal/Zoom";
// import { InView } from "react-intersection-observer";
// import Fade from "react-reveal/Fade";
import "./../Styles.css";
let activeFrom = 600;
const changeColor = 0;
let service = -100;

class Services extends Component {
  state = {
    loading: true,
    colorChanged: false,
    marginBod: false,
    loaderVisible: this.props.loaderVisible,
    serviceVisible: false,
    videoLoaded: false,
    title: "",
    paragraph: "",
    video: "",
    isOpen: false,
    services: [],
    columns: false,
    elRefs: [],
  };

  handleScroll = () => {
    const firstRef = () => {
      const { top } = this.ref.current.getBoundingClientRect();

      if (top < activeFrom) {
        this.setState({ columns: true });
      }
      if (top === changeColor || top < changeColor) {
        this.setState({ colorChanged: true });
      }
      if (top > changeColor) {
        this.setState({ colorChanged: false });
      }
    };
    firstRef();
    const secondRef = () => {
      const { top } = this.serviceRef.current.getBoundingClientRect();
      if (top === service || top < service) {
        this.setState({ serviceVisible: true });
      }
    };
    secondRef();
  };
  addMargin = () => {
    this.setState({ marginBod: true });
  };
  removeMargin = () => {
    this.setState({ marginBod: false });
  };
  openProject = (event) => {
    this.setState({ isOpen: true });
  };

  closeProject = () => {
    this.setState({ isOpen: false });
  };

  componentDidMount() {
    this.setState(this.state);
    if (this.props.location.pathname === "/services") {
      document.addEventListener("scroll", this.handleScroll);
    }
    const loading = async () => {
      if (this.state.loaderVisible) {
        this.setState({ loading: false });
      }
      setTimeout(
        () =>
          this.setState({
            loading: false,
          }),
        2500
      );
    };
    loading();
    window.onbeforeunload = (e) => {
      window.scrollTo(0, 0);
    };

    window.scrollTo(0, 0);

    const title = async () => {
      const { data } = await api.get("/services");
      this.setState({
        title: data.data.services[0].title,
        paragraph: data.data.services[0].paragraph,
        video: data.data.services[0].video,
      });
    };
    title();
    const services = async () => {
      const { data } = await api.get("/servicecolumns");
      this.setState({ services: data.data.column });
    };
    services();
  }
  componentWillUnmount() {
    document.removeEventListener("scroll", this.handleScroll);
    this.setState(null);
  }
  //  componentDidUpdate(prevProps, prevState) {
  //   // if (!hasAppeared) {
  //     document.addEventListener("aos:in", () => {
  //       console.log("animated");
  //       // setHasAppeared(true);
  //     });

  // }
  // componentDidUpdate(prevProps, prevState) {

  //   if (!this.state.videoLoaded ) {
  //     window.scrollTo(0, 0);

  //   }
  // }

  render() {
    let delay = 0;
    this.ref = React.createRef();
    this.serviceRef = React.createRef();
    // const newRef = React.createRef(new Array());
    // this.state.elRefs.push(newRef);
    return (
      <>
        <Helmet>
          <title>Services</title>
          <meta
            name="description"
            content="AD Media Agency is a Kurdistan and Iraqi-based company composed of seven departments: Branding, Corporate Social Responsibility, Video Production, AD Sound Studios, Media Buying, Digital Media, and Monitoring. AD Media Agency takes pride in having a variety of services to help our clients. "
          />
          <meta
            name="keywords"
            content="About AD Media Agency, AD Media Agency, Advertising Agency, Kurdish Advertising, Iraq Advertising, Kurdistan and Iraqi-based Advertising Company, Marketing, Brand, Graphic Design, Ad, Advertisements, Marketing Plan, Advertise, Advertising Agency, Banner, Banner Ads, video, medium definition, mediam, media buying, monitoring, audience, local media sources, national media sources, international media sources, allocate budget, reach your audience, reach audience, service, services, media buying, Video Production, video production, Music Production, music production, Social Media, social media, Monitoring, monitoring, 3D Design, 3D design, Marketing, marketing, Graphic Design, graphic design, Graphic design"
          />
          <meta name="author" content="AD Media Agency" />
        </Helmet>
        <div className={`service-layer `}>
          <section className="service-1">
            {this.state.video.length > 0 && (
              <video
                autoPlay
                loop
                muted
                playsInline
                onCanPlayThrough={() => this.setState({ videoLoaded: true })}
                // onLoadedData
                className="service-video"
              >
                <source
                  src={`https://apiv2.admedia.agency/${this.state.video}`}
                  type="video/mp4"
                />
              </video>
            )}
            <Navbar
              color={this.state.colorChanged}
              isOpen={this.openProject}
              isMargined={this.addMargin}
              removeMargin={this.removeMargin}
            />
            <div
              className={`  ${
                this.state.marginBod ? "margined-body" : "no-margined"
              }`}
            ></div>
            {!this.state.videoLoaded && (
              <div className={`loader`}>
                <div className="spinner">
                  <div className="spinner-border text-light" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            )}
            {this.state.isOpen ? (
              <Project show={true} closeProject={this.closeProject}></Project>
            ) : (
              <Project show={false} />
            )}
            <div
              ref={this.serviceRef}
              className={`container ${
                !this.state.videoLoaded && "limit-scroll"
              } `}
            >
              <div className="row title-text-row mt-5 mt-md-0">
                <div className="col-12 present-text">
                  {this.state.videoLoaded && !this.state.loaderVisible ? (
                    <h1 className="intro-text" style={{ color: "white" }}>
                      {this.state.title}
                    </h1>
                  ) : null}
                  {this.state.videoLoaded && !this.state.loaderVisible ? (
                    <p className="service-paragraph">{this.state.paragraph}</p>
                  ) : null}
                </div>
              </div>
            </div>
          </section>
        </div>
        <section
          className={`section-service-page ${
            !this.state.videoLoaded && "limit-scroll"
          }`}
          style={{ color: "#145265", backgroundColor: "white" }}
        >
          <div ref={this.ref} className="row my-4">
            {this.state.videoLoaded && !this.state.loaderVisible ? (
              <div
                data-aos="fade-up"
                className="col-12 text-center my-5 our-services-title "
              >
                OUR SERVICES
              </div>
            ) : null}
          </div>
          <div className="row mb-5 mb-md-5 services-row mx-md-4 mx-xl-5 ">
            {this.state.services.length > 0
              ? this.state.services.map((el, i) => {
                  return (
                    <ServiceColLarge
                      // ref={(el) => (newRef.current.push(el))}
                      key={el._id}
                      icon={el.icon}
                      title={el.title}
                      description={el.description}
                      // hidden={! && true}
                      style={{
                        animationDelay: `${(delay += 0.7)}s !important`,
                      }}
                    />
                  );
                })
              : null}

            {/* {console.log(newRef)} */}
          </div>
        </section>
        <section
          className={`footer-div ${!this.state.videoLoaded && "limit-scroll"}`}
        >
          <Footer />
        </section>
      </>
    );
  }
}

export default Services;
