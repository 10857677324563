import React from 'react'
import './../Styles.css'

const Audio = (props) => {
    return (
        <>
            <svg onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave} onClick={props.onClick} className="audio-svg" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                <path d="m0 131.957031h62.800781v246.542969h-62.800781zm0 0"/>
                <path d="m260.6875 0-167.886719 123.4375v263.703125l168.089844 124.859375zm0 0"/>
                <path d="m413.421875 48.5625-19.027344 23.164062c55.6875 45.742188 87.628907 113.140626 87.628907 184.910157 0 72.285156-32.324219 139.992187-88.6875 185.757812l18.898437 23.273438c63.402344-51.484375 99.765625-127.671875 99.765625-209.03125 0-80.773438-35.929688-156.613281-98.578125-208.074219zm0 0"/>
                <path d="m365.925781 106.492188-19.027343 23.164062c38.246093 31.414062 60.179687 77.695312 60.179687 126.980469 0 49.636719-22.199219 96.132812-60.90625 127.5625l18.898437 23.273437c45.75-37.148437 71.988282-92.125 71.988282-150.835937-.003906-58.289063-25.929688-113.015625-71.132813-150.144531zm0 0"/>
                <path d="m318.457031 164.378906-19.019531 23.171875c20.777344 17.058594 32.699219 42.238281 32.699219 69.085938 0 27.039062-12.0625 52.335937-33.097657 69.402343l18.890626 23.277344c28.078124-22.785156 44.183593-56.566406 44.183593-92.679687 0-35.855469-15.914062-69.484375-43.65625-92.257813zm0 0"/>
                </svg>
        </>
    )
}

export default Audio
