import React, { Component } from "react";
import api from "./api.js";
import phone from "./../svgs/phone.svg";
import gps from "./../svgs/gps.svg";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";

// import logo from "./../svgs/Logo_White.svg";
import SVG from "react-inlinesvg";
import Logosvg from "./Logosvg.js";

class Footer extends Component {
  state = {
    moto: "",
    phoneNumber: "",
    email: "",
    address: "",
    facebookUrl: "",
    youtubeUrl: "",
    instagramUrl: "",
    vimeoUrl: "",
    footerRefreshed: false,
    x: 0,
  };
  
  componentDidMount() {
    this.setState({
      footerRefreshed: true,
    });
    const x = 0;
    this.setState({ x: x + 1 });

    const footer = async () => {
      const { data } = await api.get("/footer");
      this.setState({
        moto: data.data.footer[0].moto,
        phoneNumber: data.data.footer[0].phonenumber,
        email: data.data.footer[0].email,
        address: data.data.footer[0].address,
        facebookUrl: data.data.footer[0].facebookUrl,
        youtubeUrl: data.data.footer[0].youtubeUrl,
        instagramUrl: data.data.footer[0].instagramUrl,
        vimeoUrl: data.data.footer[0].vimeoUrl,
      });
    };
    footer();
  }
  render() {
    return (
      <div className="container footer-container pl-xl-0">
        <div className="row ">
          <Fade spy={this.state.x} fraction={0.6} bottom>
            <div className="col-lg-4 mt-5 mr-4 ">
              <Logosvg></Logosvg>
              <div
                className=" moto mt-4  mb-2 column-footer ml-3 "
                style={{ maxWidth: "72%" }}
              >
                {this.state.moto}
              </div>
              <div className="phone-svg d-inline col px-0 ml-3">
                <SVG src={phone} />
              </div>
              <span className=" small mobile column-footer ml-3">
                {this.state.phoneNumber}
              </span>
              <div>
                <i className="e-icon fa fa-envelope mr-3 mt-2 ml-3 text-white"></i>
                <span className="small column-footer">{this.state.email}</span>
              </div>
              <div className="row mr-4 ml-1 ">
                <div className=" gps-col col-1">
                  <SVG src={gps} />
                </div>
                <div className="col mt-2 col-address">
                  <p className="small column-footer align-self-top d-inline ">
                    {this.state.address}
                  </p>
                </div>
              </div>
              <div>
                <ul id="icons-menu" className="pl-3  mt-4">
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={this.state.facebookUrl}
                    >
                      <i className="fa fa-facebook-square"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={this.state.instagramUrl}
                    >
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={this.state.youtubeUrl}
                    >
                      <i className="fa fa-youtube-play" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={this.state.vimeoUrl}
                    >
                      <i className="fa fa-vimeo" aria-hidden="true"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div className=" ml-3 mb-3  small column-footer">
                <span>&copy;</span> Copyright Potan Co.
              </div>
            </div>
          </Fade>
          <Fade spy={this.state.x} bottom fraction={0.6} delay={200}>
            <div className=" site-map col-6 col-md-3 col-lg-2 mt-5 small text-white ">
              <div className="mb-3 title-map">SITEMAP</div>
              <ul className="site-map-menu ">
                <li>
                  <Link className="footer-navigation" to="/">
                    Home
                  </Link>
                </li>
                <li>
                  <Link className="footer-navigation" to="/services">
                    Services
                  </Link>
                </li>
                <li>
                  <Link className="footer-navigation" to="/ourworks">
                    Our Works
                  </Link>
                </li>
                <li>
                  <Link className="footer-navigation" to="about">
                    About
                  </Link>
                </li>
              </ul>
            </div>
          </Fade>
         <Fade spy={this.state.x} bottom fraction={0.6} delay={400}>
            <div className="site-map col-6 col-md-3 col-lg-2 mt-5 small text-white ">
              <div className="mb-3 title-map">OUR COMPANY</div>
              <ul className="site-map-menu ">
                <Link className="footer-li-hover" to="/about">
                  {" "}
                  <li>Testimonial</li>
                </Link>
                <Link className="footer-li-hover" to="/about">
                  {" "}
                  <li>About Us</li>
                </Link>
              </ul>
            </div>
          </Fade>
        </div>
      </div>
    );
  }
}

export default Footer;
