import React, { Component } from "react";
import Navbar from "./../components/Navbar";
import Footer from "./../components/Footer";
import Project from "./../components/Project";
import "./../Styles.css";
import api from "./../components/api.js";
import Mediaslider from "./../components/Mediaslider";
import { Fade } from "react-awesome-reveal";
// import Fade from "react-reveal/Fade";
import { Zoom } from "react-awesome-reveal";
import AOS from "aos";
import { Helmet } from "react-helmet";

// let monitor = 330;

class mediaBuying extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMounted: false,

      mediaVisible: false,
      monitorVisible: false,

      marginBod: false,
      isOpen: false,

      video: [],
      title: [],
      paragraph: [],
      mediaParagraph: [],

      networks: [],
      colorChanged: false,
      videoLoaded: false,
    };
    this.ref = React.createRef();
    this.monitorRef = React.createRef();
    this.mediaRef = React.createRef();
  }
  addMargin = () => {
    this.setState({ marginBod: true });
  };
  removeMargin = () => {
    this.setState({ marginBod: false });
  };
  openProject = (event) => {
    event.preventDefault();
    this.setState({ isOpen: true });
  };

  closeProject = () => {
    this.setState({ isOpen: false });
  };
  handleScroll = () => {
    const color = () => {
      const { top } = this.ref.current.getBoundingClientRect();
      let changeColor = 50;

      if (top === changeColor || top < changeColor) {
        this.setState({ colorChanged: true });
      }
      if (top > changeColor) {
        this.setState({ colorChanged: false });
      }
    };
    color();
    const mediaParagraph = () => {
      const { top } = this.mediaRef.current.getBoundingClientRect();

      let width = window.innerWidth;
      let media;
      if (width > 1500) {
        media = 640;
      }
      if (width > 767 && width < 1400) {
        media = 300;
      }
      if (width < 767) {
        media = 590;
      }

      if (top === media || top < media) {
        this.setState({ mediaVisible: true });
      }
    };
    mediaParagraph();
    const monitor = () => {
      let monitor;
      let width = window.innerWidth;
      if (width > 1400) {
        monitor = 320;
      }
      if (width < 1399 && width > 767) {
        monitor = 50;
      }
      if (width < 1200) {
        monitor = 50;
      }

      const { top } = this.mediaRef.current.getBoundingClientRect();
      if (top === monitor || top < monitor) {
        this.setState({ monitorVisible: true });
      }
    };
    monitor();
  };
  componentDidMount() {
    this.setState(this.state);
    AOS.init({
      // initialise with other settings
      duration: 1500,
      offset: 250,
    });
    // setTimeout(() => this.setState({ isMounted: true }), 8000);
    this.setState({ isMounted: true });

    window.addEventListener("scroll", this.handleScroll);

    window.onbeforeunload = (e) => {
      window.scrollTo(0, 0);
    };
    window.scrollTo(0, 0);

    // const loading = async () => {
    //   if (this.state.loaderVisible) {
    //     this.setState({ loading: false });
    //   }
    //   setTimeout(
    //     () =>
    //       this.setState({
    //         loading: false,
    //       }),
    //     2500
    //   );
    // };
    // loading();

    const backgroundVideo = async () => {
      const { data } = await api.get("/mediabuying");
      this.setState({
        video: data.data.media,
        title: data.data.media,
        paragraph: data.data.paragraph,
        mediaParagraph: data.data.mediaParagraph,
        monitoringParagraph: data.data.monitoringParagraph,
      });
    };
    backgroundVideo();
    const networks = async () => {
      const { data } = await api.get("/networks");
      this.setState({ networks: data.data.networks });
    };
    networks();
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    this.setState({
      mediaVisible: false,
      monitorVisible: false,
      mediaFinished: false,
      monitorFinished: false,
      monitorLiFinished: false,
    });
  }
  componentDidUpdate() {
    // console.log(this.state.mediaFinished);
  }
  render() {
    let width = window.innerWidth;
    return (
      <>
        <Helmet>
          <title>Media Buying</title>
          <meta
            name="description"
            content="AD Media has more than 10 years of experience with local, national and international media sources. AD Media will help you allocate your budget and determine your target audience. AD Media will draft you a plan that will encompass a wide range of medium to reach your audience."
          />
          <meta
            name="keywords"
            content="About AD Media Agency, AD Media Agency, Advertising Agency, Kurdish Advertising, Iraq Advertising, Kurdistan and Iraqi-based Advertising Company, Marketing, Brand, Graphic Design, Ad, Advertisements, Marketing Plan, Advertise, Advertising Agency, Banner, Banner Ads, video, medium definition, mediam, media buying, monitoring, audience, local media sources, national media sources, international media sources, allocate budget, reach your audience, reach audience"
          />
          <meta name="author" content="AD Media Agency" />
        </Helmet>
        <div className="mediabuying-layer">
          <section className="mediabuying-1">
            {this.state.video.length > 0 && (
              <video
                autoPlay
                loop
                muted
                playsInline
                onCanPlayThrough={() => this.setState({ videoLoaded: true })}
                className="mediabuying-video"
              >
                <source
                  src={`https://apiv2.admedia.agency/${this.state.video[0].video}`}
                  type="video/mp4"
                />
              </video>
            )}

            <Navbar
              color={this.state.colorChanged}
              isOpen={this.openProject}
              isMargined={this.addMargin}
              removeMargin={this.removeMargin}
            />
            <div
              className={` no-margined ${
                this.state.marginBod ? "margined-body" : null
              }`}
            >
              {" "}
            </div>
            {!this.state.videoLoaded ? (
              <div className={`loader`}>
                <div className="spinner">
                  <div className="spinner-border text-light" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            ) : null}
            {this.state.isOpen ? (
              <Project show={true} closeProject={this.closeProject}></Project>
            ) : (
              <Project show={false} />
            )}
            <div className="container ">
              <div className="row title-text-row mt-5 mt-md-0">
                <div className="col-12">
                  {this.state.videoLoaded && !this.state.loaderVisible ? (
                    <h1 className="intro-text" style={{ color: "white" }}>
                      {this.state.video.length > 0 && this.state.video[0].title}
                    </h1>
                  ) : null}
                  {this.state.videoLoaded && !this.state.loaderVisible ? (
                    <p className="service-paragraph">
                      {this.state.video.length > 0 &&
                        this.state.video[0].paragraph}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          </section>
        </div>
        <section
          ref={this.ref}
          className={`media-buying ${
            !this.state.videoLoaded && "limit-scroll"
          }`}
        >
          <div className="contianer">
            <div className="row">
              <div className="col-md-4 text-center mt-5 mt-md-0 my-md-5">
                {this.state.mediaVisible ? (
                  <div
                    data-aos="fade-up"
                    data-aos-once="true"
                    className="media-buying-title"
                    style={{ fontSize: "2rem", color: "var(--primary)" }}
                  >
                    Media Buying
                  </div>
                ) : (
                  <div className="media-buying-title" style={{ opacity: 0 }}>
                    Media Buying
                  </div>
                )}

                <div className="my-5">
                  {this.state.mediaVisible ? (
                    <div
                      data-aos="fade-up"
                      data-aos-once="true"
                      data-aos-delay="300"
                    >
                      <svg
                        className="media-buying-svg"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 64.003 64"
                      >
                        <defs></defs>
                        <path d="M22.668,45.335H4a4.005,4.005,0,0,1-4-4V4A4.005,4.005,0,0,1,4,0H44a4.005,4.005,0,0,1,4,4V28a1.333,1.333,0,0,1-2.667,0V4A1.336,1.336,0,0,0,44,2.667H4A1.336,1.336,0,0,0,2.667,4V41.335A1.336,1.336,0,0,0,4,42.668H22.668a1.333,1.333,0,0,1,0,2.667Z" />
                        <g transform="translate(10.667 13.334)">
                          <path
                            d="M13.334,21A1.334,1.334,0,0,1,12,19.667V10.334a2.667,2.667,0,0,0-5.334,0v9.334a1.333,1.333,0,0,1-2.667,0V10.334a5.334,5.334,0,0,1,10.667,0v9.334A1.334,1.334,0,0,1,13.334,21Z"
                            transform="translate(-4 -5)"
                          />
                        </g>
                        <g transform="translate(10.667 21.334)">
                          <path
                            d="M13.334,10.667h-8A1.333,1.333,0,0,1,5.333,8h8a1.333,1.333,0,0,1,0,2.667Z"
                            transform="translate(-4 -8)"
                          />
                        </g>
                        <g transform="translate(26.668 13.334)">
                          <path
                            d="M14,21H11.333A1.334,1.334,0,0,1,10,19.667V6.333A1.334,1.334,0,0,1,11.333,5H14a6.675,6.675,0,0,1,6.667,6.667v2.667A6.675,6.675,0,0,1,14,21Zm-1.333-2.667H14a4.005,4.005,0,0,0,4-4V11.667a4.005,4.005,0,0,0-4-4H12.667Z"
                            transform="translate(-10 -5)"
                          />
                        </g>
                        <g transform="translate(36.69 37.214)">
                          <path
                            d="M25.3,23.055a1.339,1.339,0,0,1-.944-.389L18.7,17.009A1.335,1.335,0,0,0,16.811,18.9a1.332,1.332,0,1,1-1.885,1.883,4,4,0,0,1,5.659-5.654l5.656,5.656a1.329,1.329,0,0,1-.941,2.272Z"
                            transform="translate(-13.758 -13.954)"
                          />
                        </g>
                        <g transform="translate(42.346 35.327)">
                          <path
                            d="M25.535,20.463a1.339,1.339,0,0,1-.944-.389L20.82,16.3a1.333,1.333,0,0,0-1.885,1.885,1.333,1.333,0,0,1-1.885,1.885,4,4,0,0,1,5.656-5.656l3.771,3.771a1.332,1.332,0,0,1-.941,2.275Z"
                            transform="translate(-15.879 -13.247)"
                          />
                        </g>
                        <g transform="translate(27.257 33.439)">
                          <path
                            d="M31.694,43.1a11.922,11.922,0,0,1-2.944-.368l-9.3-2.371a1.342,1.342,0,0,1-.824-.619,4.791,4.791,0,0,1,.776-5.758,5.113,5.113,0,0,1,3.36-1.355L11.392,21.257A4,4,0,1,1,17.048,15.6L28.363,26.913A1.333,1.333,0,0,1,26.478,28.8L15.163,17.483a1.333,1.333,0,0,0-1.885,1.885L27.422,33.511a1.334,1.334,0,0,1-1.363,2.208c-1.136-.376-3.8-.829-4.768.149a2.024,2.024,0,0,0-.544,2.069l8.664,2.211a9.374,9.374,0,0,0,8.9-2.445l3.248-3.248a9.335,9.335,0,0,0,0-13.2L35.91,15.592a1.337,1.337,0,0,0-1.885,0,1.33,1.33,0,0,0,0,1.885,1.334,1.334,0,1,1-1.885,1.888A4,4,0,0,1,37.8,13.707l5.656,5.659a12,12,0,0,1,0,16.971L40.2,39.585a12.049,12.049,0,0,1-8.51,3.515Z"
                            transform="translate(-10.221 -12.539)"
                          />
                        </g>
                      </svg>
                    </div>
                  ) : (
                    <svg
                      className="media-buying-svg"
                      style={{ opacity: 0 }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 64.003 64"
                    >
                      <defs></defs>
                      <path d="M22.668,45.335H4a4.005,4.005,0,0,1-4-4V4A4.005,4.005,0,0,1,4,0H44a4.005,4.005,0,0,1,4,4V28a1.333,1.333,0,0,1-2.667,0V4A1.336,1.336,0,0,0,44,2.667H4A1.336,1.336,0,0,0,2.667,4V41.335A1.336,1.336,0,0,0,4,42.668H22.668a1.333,1.333,0,0,1,0,2.667Z" />
                      <g transform="translate(10.667 13.334)">
                        <path
                          d="M13.334,21A1.334,1.334,0,0,1,12,19.667V10.334a2.667,2.667,0,0,0-5.334,0v9.334a1.333,1.333,0,0,1-2.667,0V10.334a5.334,5.334,0,0,1,10.667,0v9.334A1.334,1.334,0,0,1,13.334,21Z"
                          transform="translate(-4 -5)"
                        />
                      </g>
                      <g transform="translate(10.667 21.334)">
                        <path
                          d="M13.334,10.667h-8A1.333,1.333,0,0,1,5.333,8h8a1.333,1.333,0,0,1,0,2.667Z"
                          transform="translate(-4 -8)"
                        />
                      </g>
                      <g transform="translate(26.668 13.334)">
                        <path
                          d="M14,21H11.333A1.334,1.334,0,0,1,10,19.667V6.333A1.334,1.334,0,0,1,11.333,5H14a6.675,6.675,0,0,1,6.667,6.667v2.667A6.675,6.675,0,0,1,14,21Zm-1.333-2.667H14a4.005,4.005,0,0,0,4-4V11.667a4.005,4.005,0,0,0-4-4H12.667Z"
                          transform="translate(-10 -5)"
                        />
                      </g>
                      <g transform="translate(36.69 37.214)">
                        <path
                          d="M25.3,23.055a1.339,1.339,0,0,1-.944-.389L18.7,17.009A1.335,1.335,0,0,0,16.811,18.9a1.332,1.332,0,1,1-1.885,1.883,4,4,0,0,1,5.659-5.654l5.656,5.656a1.329,1.329,0,0,1-.941,2.272Z"
                          transform="translate(-13.758 -13.954)"
                        />
                      </g>
                      <g transform="translate(42.346 35.327)">
                        <path
                          d="M25.535,20.463a1.339,1.339,0,0,1-.944-.389L20.82,16.3a1.333,1.333,0,0,0-1.885,1.885,1.333,1.333,0,0,1-1.885,1.885,4,4,0,0,1,5.656-5.656l3.771,3.771a1.332,1.332,0,0,1-.941,2.275Z"
                          transform="translate(-15.879 -13.247)"
                        />
                      </g>
                      <g transform="translate(27.257 33.439)">
                        <path
                          d="M31.694,43.1a11.922,11.922,0,0,1-2.944-.368l-9.3-2.371a1.342,1.342,0,0,1-.824-.619,4.791,4.791,0,0,1,.776-5.758,5.113,5.113,0,0,1,3.36-1.355L11.392,21.257A4,4,0,1,1,17.048,15.6L28.363,26.913A1.333,1.333,0,0,1,26.478,28.8L15.163,17.483a1.333,1.333,0,0,0-1.885,1.885L27.422,33.511a1.334,1.334,0,0,1-1.363,2.208c-1.136-.376-3.8-.829-4.768.149a2.024,2.024,0,0,0-.544,2.069l8.664,2.211a9.374,9.374,0,0,0,8.9-2.445l3.248-3.248a9.335,9.335,0,0,0,0-13.2L35.91,15.592a1.337,1.337,0,0,0-1.885,0,1.33,1.33,0,0,0,0,1.885,1.334,1.334,0,1,1-1.885,1.888A4,4,0,0,1,37.8,13.707l5.656,5.659a12,12,0,0,1,0,16.971L40.2,39.585a12.049,12.049,0,0,1-8.51,3.515Z"
                          transform="translate(-10.221 -12.539)"
                        />
                      </g>
                    </svg>
                  )}
                </div>
              </div>

              <div className="col-md-5 my-md-5">
                <ul
                  ref={this.mediaRef}
                  className={`text-left  
                     media-buying-info
                `}
                >
                  {this.state.mediaVisible ? (
                    <div
                      data-aos="fade-up"
                      data-aos-once="true"
                      data-aos-delay="500"
                      className=" media-buying-info"
                    >
                      {this.state.video.length > 0 &&
                        this.state.video[0].mediaParagraph.map((li, i) => {
                          return (
                            <li key={i} className="mb-5 ">
                              {li}{" "}
                            </li>
                          );
                        })}
                    </div>
                  ) : (
                    this.state.video.length > 0 &&
                    this.state.video[0].mediaParagraph.map((li, i) => {
                      return (
                        <li key={i} className="mb-5 hidden-li-monitoring ">
                          {li}{" "}
                        </li>
                      );
                    })
                  )}
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className={`${!this.state.videoLoaded && "limit-scroll"}`}>
          <div className="contianer">
            <div className="row">
              <div className="col-md-4 text-center my-md-5">
                {this.state.monitorVisible && (
                  <>
                    {" "}
                    <div
                      data-aos="fade-up"
                      data-aos-delay="300"
                      data-aos-once="true"
                    >
                      <div
                        className=" display-5 "
                        style={{ fontSize: "2rem", color: "var(--primary)" }}
                      >
                        Monitoring{" "}
                      </div>
                    </div>
                    <div className="my-5">
                      <div
                        data-aos="fade-up"
                        data-aos-once="true"
                        data-aos-delay="500"
                      >
                        <svg
                          className="media-buying-svg"
                          xmlns="http://www.w3.org/2000/svg"
                          width="60px"
                          viewBox="0 0 512 512"
                        >
                          <g>
                            <path d="M472,128H461.61a72,72,0,1,0-107.22,0H256V48a8,8,0,0,0-8-8H216a8,8,0,0,0-8,8v80h-8V16a8,8,0,0,0-8-8H160a8,8,0,0,0-8,8V128h-8V72a8,8,0,0,0-8-8H104a8,8,0,0,0-8,8v56H40A32.036,32.036,0,0,0,8,160V400a32.036,32.036,0,0,0,32,32H204.9l-10.67,32H168a8,8,0,0,0-8,8v16H80a8,8,0,0,0,0,16H432a8,8,0,0,0,0-16H352V472a8,8,0,0,0-8-8H317.77L307.1,432H472a32.036,32.036,0,0,0,32-32V160A32.036,32.036,0,0,0,472,128ZM416,24.58A56.11,56.11,0,0,1,463.42,72H416ZM411.31,88h52.11a55.962,55.962,0,0,1-88.94,36.83ZM352,80a56.086,56.086,0,0,1,48-55.42V76.69l-36.83,36.83A55.708,55.708,0,0,1,352,80ZM224,56h16V208H224Zm-24,88h8v64h-8ZM168,24h16V208H168ZM144,144h8v64h-8ZM112,80h16V208H112ZM336,480v8H176v-8ZM211.1,464l10.67-32h68.46l10.67,32ZM488,400a16.021,16.021,0,0,1-16,16H40a16.021,16.021,0,0,1-16-16V384H488Zm0-32H24V160a16.021,16.021,0,0,1,16-16H96v64H88V160a8,8,0,0,0-8-8H48a8,8,0,0,0-8,8v56a8,8,0,0,0,8,8H304a8,8,0,0,0,8-8V160a8,8,0,0,0-8-8H272a8,8,0,0,0-8,8v48h-8V144H375.08a71.733,71.733,0,0,0,65.84,0H472a16.021,16.021,0,0,1,16,16ZM72,168v40H56V168Zm208,40V168h16v40Z" />
                            <path d="M96,392H48a8,8,0,0,0,0,16H96a8,8,0,0,0,0-16Z" />
                            <path d="M128,392h-8a8,8,0,0,0,0,16h8a8,8,0,0,0,0-16Z" />
                            <path d="M344,208a7.974,7.974,0,0,0,5.657-2.343L379.313,176H448a8,8,0,0,0,0-16H376a8,8,0,0,0-5.657,2.343l-32,32A8,8,0,0,0,344,208Z" />
                            <path d="M448,296a24,24,0,0,0-20.75,11.97L343.5,259.11a24,24,0,0,0-47.11.6l-84.28,63.21a23.979,23.979,0,0,0-41.48,2.18l-83.3-34.71a24.01,24.01,0,1,0-1.96,16.51l83.3,34.71a23.995,23.995,0,0,0,46.94-1.32l84.28-63.21a23.973,23.973,0,0,0,40.86-1.05l83.75,48.86A24,24,0,1,0,448,296Zm-376,.04a8.02,8.02,0,1,1,0-.04ZM192,344a8.011,8.011,0,0,1-8-8v-.04a8,8,0,1,1,8,8.04Zm128-72a8,8,0,1,1,8-8A8.011,8.011,0,0,1,320,272Zm128,56a8,8,0,1,1,8-8A8.011,8.011,0,0,1,448,328Z" />
                            <path d="M384,344H328a8,8,0,0,0,0,16h56a8,8,0,0,0,0-16Z" />
                            <path d="M384,320H360a8,8,0,0,0,0,16h24a8,8,0,0,0,0-16Z" />
                            <path d="M48,248h88a8,8,0,0,0,0-16H48a8,8,0,0,0,0,16Z" />
                            <path d="M160,248h8a8,8,0,0,0,0-16h-8a8,8,0,0,0,0,16Z" />
                          </g>
                        </svg>
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className="col-md-5 my-md-5">
                <ul
                  className={`text-left media-buying-info    
                  `}
                >
                  {this.state.monitorVisible ? (
                    <div
                      data-aos="fade-up"
                      data-aos-once="true"
                      data-aos-delay="700"
                      className=" media-buying-info"
                    >
                      {this.state.video.length > 0 &&
                        this.state.video[0].monitoringParagraph.map((li, i) => {
                          return (
                            <li key={i} className="mb-5 mt-2 ">
                              {li}{" "}
                            </li>
                          );
                        })}
                    </div>
                  ) : (
                    this.state.video.length > 0 &&
                    this.state.video[0].monitoringParagraph.map((li, i) => {
                      return (
                        <li key={i} className="mb-5 mt-2 hidden-li-monitoring">
                          {li}{" "}
                        </li>
                      );
                    })
                  )}
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section
          className={`net-we-work ${
            !this.state.videoLoaded && "limit-scroll"
          } `}
        >
          <div className={`container `}>
            <div className="row my-5">
              <div className="col-md-6">
                <Zoom
                  fraction={0.8}
                  delay={width > 1500 ? 2500 : 100}
                  triggerOnce={true}
                >
                  <h1 className="network-header">Networks We Work With</h1>
                </Zoom>
              </div>
            </div>
            <div className="section-media-slider my-5">
              <Fade
                friction={0.9}
                delay={width > 1500 ? 2700 : 300}
                triggerOnce={true}
              >
                <Mediaslider></Mediaslider>
              </Fade>
            </div>
          </div>
        </section>
        <section
          className={`footer-div ${!this.state.videoLoaded && "limit-scroll"}`}
        >
          <Footer></Footer>
        </section>
      </>
    );
  }
}

export default mediaBuying;
