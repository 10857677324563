import React, { Component } from "react";
import Navbar from "./../components/Navbar";
import Footer from "./../components/Footer";
import ServicesColumn from "./../components/ServicesColumn";
import Project from "./../components/Project";
import Slideshow from "./../components/Slideshow";
import Workcol from "./../components/Workscol";
import api from "./../components/api.js";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import Svg from "../components/Svg.js";
import TitleSlider from "../components/TitleSlider";
import TitleSliderMobile from "../components/TitleSliderMobile";
import AOS from "aos";
import { Helmet } from "react-helmet";

// import Slider from "react-slick";
// import Slider from "react-animated-slider";
// import "react-animated-slider/build/vertical.css";
import "./../Styles.css";
import "./../slick.css";

const activeFrom = -250;
const activeClients = -1080;
const changeColor = 100;

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMounted: false,
      colorChanged: false,
      loading: true,
      loaderSvg: true,
      marginBod: false,
      isOpen: false,
      home: [],
      works: [],
      darkendBody: false,
      isActive: false,
      clientActive: false,
      textDelayed: this.props.delay,
      logos: [],
      services: [],
      loaderVisible: this.props.loaderVisible,
      hoveredEl: [],
      backVideo: false,
      backVideo2: false,
      backVideo3: false,
      backVideo4: false,
      mobile: [],
      scrolled: false,
      titleData: [],
    };

    this.ref = React.createRef();
    this.clientRef = React.createRef();
    this.videoRef = React.createRef();
  }

  toggleHover = (id) => {
    this.setState({
      hoveredEl: id,
    });
  };
  endHover = () => {
    this.setState({ hoveredEl: [] });
  };
  addMargin = () => {
    this.setState({ marginBod: true });
  };
  removeMargin = () => {
    this.setState({ marginBod: false });
  };

  myDivToFocus = React.createRef();
  handleOnClick = (event) => {
    //.current is verification that your element has rendered
    if (this.myDivToFocus.current) {
      this.myDivToFocus.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };

  openProject = (event) => {
    event.preventDefault();
    this.setState({ isOpen: !this.state.isOpen });
  };

  closeProject = () => {
    this.setState({ isOpen: false });
  };

  handleScroll = () => {
    const otherScrolls = () => {
      const { top } = this.ref.current.getBoundingClientRect();
      if (top < activeFrom) {
        this.setState({ isActive: true });
      }
      if (top < activeClients) {
        this.setState({ clientActive: true });
      }
    };
    otherScrolls();
    const colorHandler = () => {
      const { top } = this.clientRef.current.getBoundingClientRect();

      if (top === changeColor || top < changeColor) {
        this.setState({ colorChanged: true });
      } else {
        this.setState({ colorChanged: false });
      }
    };

    colorHandler();
  };

  componentDidUpdate(prevProps, prevState) {
    // const position = window.pageYOffset;
    // console.log(position);
    // if (this.state.loaderSvg !== prevState.loaderSvg && position > 100) {
    //   this.setState({ loaderSvg: false });
    // }
    let visited = sessionStorage.getItem("visited");
    if (this.state.backVideo !== prevState.backVideo && !visited) {
      setTimeout(
        () => this.setState({ loaderSvg: false, scrolled: true }),
        4000
      );

      setTimeout(() => sessionStorage.setItem("visited", true), 6000);
    }
    if (this.state.backVideo !== prevState.backVideo && visited) {
      this.setState({ loaderSvg: false, scrolled: true });
    }

    if (this.state.scrolled !== prevState.scrolled) {
      window.scrollTo(0, 0);
      this.setState((state, props) => {
        return { scrolled: false };
      });
    }
    if (this.state.loaderSvg !== prevState.loaderSvg) {
      window.scrollTo(0, 0);
    }
  }

  componentDidMount() {
    this.setState({ colorChanged: false });

    window.addEventListener("scroll", this.handleScroll);

    AOS.init({
      // initialise with other settings
      duration: 1500,
      offset: 250,
    });

    window.onbeforeunload = (e) => {
      window.scrollTo(0, 0);
    };

    window.scrollTo(0, 0);
    const home = async () => {
      const { data } = await api.get("/home");
      this.setState({ home: data.data.home });
    };
    home();
    const works = async () => {
      const { data } = await api.get("/works");
      this.setState({ works: data.data.work });
    };
    const mobile = async () => {
      const { data } = await api.get("/mobile");

      this.setState({ mobile: data.mobileText });
    };
    mobile();

    works();
    const services = async () => {
      const { data } = await api.get("/servicecolumns");
      this.setState({ services: data.data.column });
    };
    services();
    const loading = () => {
      if (this.state.loaderVisible) {
        this.setState({ loading: false });
      } else {
        setTimeout(
          () =>
            this.setState({
              loading: false,
            }),
          2500
        );
      }
    };
    loading();
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    this.setState({ loaderSvg: false, colorChanged: false });
    this.setState({ isMounted: false });
  }

  render() {
    // const settings = {
    //   dots: false,
    //   infinite: true,
    //   slidesToShow: 1,
    //   slidesToScroll: 1,
    //   autoplay: true,
    //   centerMode: true,
    //   autoplaySpeed: 4000,
    //   arrows: false,
    // };
    let width = window.innerWidth;

    let visited = sessionStorage.getItem("visited");
    return (
      <>
        <Helmet>
          <title>Admedia Agency</title>
          <meta
            name="description"
            content="AD Media Agency is a Kurdistan and Iraqi-based company that specializes in providing, branding and advertisement services to customers. We help brands build relationships with consumers to deliver the best value. With AD Media, you will cover all dimenstions."
          />
          <meta
            name="keywords"
            content="About AD Media Agency, AD Media Agency, Advertising Agency, Kurdish Advertising, Iraq Advertising, Kurdistan and Iraqi-based Advertising Company, Marketing, Brand, Graphic Design, Ad, Advertisements, Marketing Plan, Advertise, Advertising Agency, Banner, Banner Ads"
          />
          <meta name="author" content="AD Media Agency" />
        </Helmet>

        {!this.state.backVideo &&
          !this.state.backVideo2 &&
          !this.state.backVideo3 &&
          !this.state.backVideo4 && (
            <div className={`loader`}>
              <div className="spinner">
                <div className="spinner-border text-light" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          )}
        {!visited && (
          <Svg
            className={
              this.state.loaderSvg ? "opening-div" : "animate-opening-div"
            }
          />
        )}

        <div ref={this.ref} className="home-layer">
          <section className={`child home-1`}>
            {this.state.home.length > 0 && (
              <video
                autoPlay
                loop
                muted
                playsInline
                onCanPlayThrough={() => this.setState({ backVideo: true })}
                className={`${
                  !this.state.loaderSvg ? "home-video" : "intro-vid"
                }`}
              >
                <source
                  src={`https://apiv2.admedia.agency/${this.state.home[0].video}`}
                  type="video/mp4"
                />
              </video>
              //  {/* </Suspense> */}
            )}

            <Navbar
              color={this.state.colorChanged}
              isMargined={this.addMargin}
              removeMargin={this.removeMargin}
              isOpen={this.openProject}
            />
            <div
              className={`  ${
                this.state.marginBod ? "margined-body" : "no-margined"
              }`}
            ></div>

            {this.state.isOpen ? (
              <Project show={true} closeProject={this.closeProject}></Project>
            ) : (
              <Project show={false} />
            )}
            <div className="container">
              <div className="row title-text-row mt-5 mt-md-0">
                <div className="col-12">
                  {!this.state.loaderSvg &&
                  !this.state.loaderVisible &&
                  width > 767 ? (
                    <>
                      <TitleSlider
                        show={this.state.loaderSvg}
                        data={[
                          {
                            title:
                              this.state.home.length > 0 &&
                              this.state.home[0].mainTitle,
                          },
                          {
                            title:
                              this.state.home.length > 0 &&
                              this.state.home[0].secondTitle,
                          },
                          {
                            title:
                              this.state.home.length > 0 &&
                              this.state.home[0].thirdTitle,
                          },
                        ]}
                      />
                    </>
                  ) : null}
                  {!this.state.loaderSvg &&
                    !this.state.loaderVisible &&
                    width < 767 && (
                      <TitleSliderMobile
                        show={this.state.loaderSvg}
                        data={[
                          {
                            title:
                              this.state.mobile.length > 0 &&
                              this.state.mobile[0].mobileTitle1,
                          },
                          {
                            title:
                              this.state.mobile.length > 0 &&
                              this.state.mobile[0].mobileTitle2,
                          },
                          {
                            title:
                              this.state.mobile.length > 0 &&
                              this.state.mobile[0].mobileTitle3,
                          },
                        ]}
                      />

                      // <div className="mobile-text row d-flex justify-content-center">
                    )}
                </div>
              </div>
              <div className="row explore-row">
                <div className="col-12 mb-3">
                  {!this.state.loaderSvg && (
                    <div onClick={this.handleOnClick} className="explore-hover">
                      <span className="explore d-block mb-1">Explore</span>

                      <svg
                        className="explore-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        width="21.919"
                        height="24"
                        viewBox="0 0 21.919 24"
                      >
                        <defs></defs>
                        <g transform="translate(0 24) rotate(-90)">
                          <g transform="translate(0.001 0)">
                            <path
                              d="M23.1.147,11.993,11.256.884.147A.522.522,0,0,0,.146.884L11.624,12.362a.522.522,0,0,0,.738,0L23.84.884A.522.522,0,0,0,23.1.146Z"
                              transform="translate(0 9.404)"
                            />
                            <path d="M23.1.159,11.993,11.269.884.159A.522.522,0,0,0,.146.9L11.624,12.375a.522.522,0,0,0,.738,0L23.84.9a.522.522,0,1,0-.725-.751L23.1.159Z" />
                          </g>
                          <path
                            d="M11.994,12.515a.522.522,0,0,1-.369-.152L.146.885A.522.522,0,0,1,.884.147L11.994,11.256,23.1.146a.522.522,0,0,1,.738.738L12.362,12.362A.522.522,0,0,1,11.994,12.515Z"
                            transform="translate(0 9.404)"
                          />
                          <path
                            d="M11.994,12.528a.522.522,0,0,1-.369-.152L.146.9A.522.522,0,0,1,.884.16L11.994,11.269,23.1.159a.522.522,0,0,1,.751.725L23.84.9,12.362,12.375A.522.522,0,0,1,11.994,12.528Z"
                            transform="translate(0)"
                          />
                        </g>
                      </svg>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
        {/* extra videos for svg loader*/}
        {!visited && (
          <section>
            {this.state.loaderSvg &&
              this.state.home.length > 0 &&
              width > 767 && (
                <div style={{ height: "100vh !important" }}>
                  <video
                    autoPlay
                    loop
                    muted
                    playsInline
                    onCanPlayThrough={() => this.setState({ backVideo2: true })}
                    className="intro-vid"
                  >
                    <source
                      src={`https://apiv2.admedia.agency/${this.state.home[0].video}`}
                      type="video/mp4"
                    />
                  </video>
                </div>
              )}
          </section>
        )}
        {!visited && (
          <section ref={this.videoRef}>
            {this.state.loaderSvg &&
              this.state.home.length > 0 &&
              width > 767 && (
                <div style={{ height: "100vh !important" }}>
                  <video
                    autoPlay
                    loop
                    muted
                    playsInline
                    onCanPlayThrough={() => this.setState({ backVideo3: true })}
                    className="intro-vid"
                  >
                    <source
                      src={`https://apiv2.admedia.agency/${this.state.home[0].video}`}
                      type="video/mp4"
                    />
                  </video>
                </div>
              )}
          </section>
        )}

        <section
          className={`child section-2 ${
            this.state.loaderSvg && "limit-scroll"
          } `}
          ref={this.myDivToFocus}
        >
          <div className="row">
            <div className="col-12 text-center latest-works-column">
              <Zoom fraction={0.7} delay={600}>
                <div className="textTitle-2 ">LATEST WORKS</div>
              </Zoom>
            </div>
          </div>

          <div className="container mt-5 pb-5">
            <div className="row my-md-4 d-flex">
              {this.state.home.length > 0 &&
                this.state.works.slice(0, 4).map((w) => {
                  return (
                    <Workcol
                      // onClick={() => <Link to={w.link} />}
                      onMouseEnter={() => this.toggleHover(w._id)}
                      onMouseLeave={this.endHover}
                      key={w._id}
                      hovered={
                        this.state.hoveredEl.length > 0 &&
                        w._id === this.state.hoveredEl
                          ? true
                          : false
                      }
                      name={
                        this.state.hoveredEl.length > 0 &&
                        w._id === this.state.hoveredEl
                          ? null
                          : w.name
                      }
                      src={w.link}
                    />
                  );
                })}
            </div>
            <div className="row">
              <div className="col-lg-11 col-xl-12 text-white d-flex justify-content-end ">
                <div className="seemore-hover">
                  <Link className="seemore-col" to="/ourworks">
                    <h6
                      style={{ cursor: "pointer" }}
                      className="d-inline mr-4 align-self-center seemore-col "
                    >
                      SEE MORE
                    </h6>
                  </Link>
                  <Link className="seemore-col" to="/ourworks">
                    <i
                      style={{ cursor: "pointer" }}
                      className="d-inline fa fa-long-arrow-right fa-2x seemore-col-icon "
                      aria-hidden="true"
                    ></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className={`child services-section ${
            this.state.loaderSvg && "limit-scroll"
          }`}
          style={{ height: "100%" }}
        >
          {this.state.home.length > 0 && (
            <video autoPlay loop muted playsInline className="service-video">
              <source
                src={`https://apiv2.admedia.agency/${this.state.home[0].video}`}
                type="video/mp4"
              />
            </video>
          )}

          <div className="container">
            <div className="row">
              <Zoom fraction={1}>
                <div
                  className="col-12 text-center  our-services "
                  style={{ marginTop: "2rem", marginBottom: "2rem" }}
                >
                  OUR SERVICES
                </div>
              </Zoom>
            </div>
            <div
              className={`row services-row-column d-flex justify content-between  `}
            >
              {this.state.services.map((el) => {
                return (
                  <ServicesColumn
                    key={el._id}
                    margin=""
                    title={el.title}
                    icon={
                      <SVG src={`https://apiv2.admedia.agency/${el.icon}`} />
                    }
                  ></ServicesColumn>
                );
              })}
            </div>
          </div>
        </section>
        <section
          ref={this.clientRef}
          className={`clientshome-section text-center  container ${
            this.state.loaderSvg && "limit-scroll"
          }`}
        >
          <Zoom fraction={0.7}>
            <h1 className="our-clients-home ">OUR CLIENTS</h1>
          </Zoom>
          <Fade fraction={0.9}>
            <Slideshow></Slideshow>{" "}
          </Fade>
        </section>
        <section
          className={`child footer-div ${
            this.state.loaderSvg && "limit-scroll"
          }`}
        >
          <Footer />
        </section>
      </>
    );
  }
}

export default Home;
