import React, { Component } from "react";
import api from "./api.js";
// import './Slider.scss'
import Slider from "react-slick";



class Slideshow extends Component {
  state = { networks: [] };

  componentDidMount() {
    const networks = async () => {
      const { data } = await api.get("/networks");
      this.setState({ networks: data.data.networks });
    };
    networks();
  }
  render() {
    const speed = 0;
    const settings = {
      dots: false,
      infinite: true,
      slidesToShow: 9,
      slidesToScroll: 1,
      autoplay: true,
      speed: 4000,
      autoplaySpeed: speed,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 560,
          settings: {
            slidesToShow: 4,
          },
        },
      ],
    };
    return (
      <>
        
        <div>
          <Slider {...settings}>
            {this.state.networks.map((el) => (
              
              <div className="media-div-parent" key={el._id}>
                <img
                  className={`img-fluid media-img-slider `}
                  src={`https://apiv2.admedia.agency/${el.network}`}
                  alt="slider-img"
                  // style={imgStyles}
                />
              </div>
            ))}
          </Slider>
        </div>
      </>
    );
  }
}

export default Slideshow;
