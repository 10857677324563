import React, { useState, useEffect } from "react";

import "./../Styles.css";
import "./../slick.css";

const TitleSlider = (props) => {
  const [index, SetIndex] = useState(0);
  const [initiate, setInitiate] = useState(false);

  useEffect(() => {
    const lastIndex = props.data.length - 1;
    if (initiate) {
      if (index < 0) {
        SetIndex(lastIndex);
      }
      if (index > lastIndex) {
        SetIndex(0);
      }
    }
  }, [index, initiate, props.data]);

  useEffect(() => {
    if (initiate) {
      let slider = setInterval(() => {
        SetIndex(index + 1);
      }, 7000);
      return () => clearInterval(slider);
    }
  }, [index, initiate]);
  useEffect(() => {
    !props.show && setTimeout(() => setInitiate(true), 1000);
  });

  return (
    <>
      {props.data.length > 0 &&
        props.data.map((el, elIndex) => {
          let position = "next-slide";

          if (index === elIndex) {
            position = "current-slide";
          }
          if (
            elIndex === index - 1 ||
            (index === 0 && elIndex === props.data.length - 1)
          ) {
            position = "last-slide";
          }
          return (
            <h3
              key={el.title}
              className={` intro-text-home d-inline text-center ${position}`}
            >
              {initiate && el.title}
            </h3>
          );
        })}
    </>
  );
};

export default TitleSlider;
