import React, { Component } from "react";

class Logosvg extends Component {
  render() {
    return (
      <>
        <svg
          className="logo-footer"
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 538.01 477.18"
        >
          <defs>
            <style>.cls-1{`fill:#fff;fill-rule:evenodd;`}</style>
          </defs>
          <title>Logo_White</title>
          <path
            className="cls-2"
            d="M178.13,14.5H288.76Q173.53,228.43,58.28,442.44l-.11-.06L22.11,359.64l-9.42-21.59q-1.41-3.21-2.8-6.42A8.8,8.8,0,0,1,8.8,329Z"
            transform="translate(-8.8 -14.5)"
          />
          <path
            className="cls-2"
            d="M300.06,14.53H405.42L495.25,221,436.83,329.4v-.11C436.83,329.4,300.06,14.53,300.06,14.53Z"
            transform="translate(-8.8 -14.5)"
          />
          <path
            className="cls-2"
            d="M294.46,14.52q69.72,159.84,139.46,319.69L375.56,442.82l-.11-.05L268.92,197.88l-22.68-52.12-7-16.11q-1-2.33-2-4.65a9.35,9.35,0,0,1-1-2.53Z"
            transform="translate(-8.8 -14.5)"
          />
          <path
            className="cls-2"
            d="M497.32,226.06,546.8,339.6,491.29,442.79H380.82Z"
            transform="translate(-8.8 -14.5)"
          />
          <path
            className="cls-2"
            d="M105.08,365.58H298.29l71.95,77v.05H63.65v-.05Z"
            transform="translate(-8.8 -14.5)"
          />
          <path
            className="cls-2"
            d="M355.06,476.32h-9V474h11.62c.07,8,0,12.22-4.77,15.51-6.19,4.3-15,1.28-17.42-4.38-1.06-2.48-1-6-1-9.54,0-8.22-.13-13.27,5-16.49a13.71,13.71,0,0,1,3.5-1.54,12.19,12.19,0,0,1,4.77-.22c5.68,1,8.7,4.24,9.86,9.76H355V467l-.6-1.92a9.35,9.35,0,0,0-3.07-3.94,9.05,9.05,0,0,0-3.07-1.37,9.83,9.83,0,0,0-5.91.6c-5.08,2.2-5.26,6.81-5.26,14,0,3.58-.11,7.48,1,10,1.79,4.17,8.11,6.49,12.88,3.51C354.42,485.69,355.09,482.17,355.06,476.32Z"
            transform="translate(-8.8 -14.5)"
          />
          <path
            className="cls-2"
            d="M460.39,467.12H457.7c-.36-5.09-6.56-9.3-12.33-6.91-3.29,1.37-4.66,3.82-5.31,7.89-.06,2.11-.11,4.21-.17,6.31,0,6.28.05,10.63,3.78,13.26,5.81,4.09,13.26,0,14.14-6h2.58c-.46,6.57-8.15,12.14-15.79,9.16-4-1.57-6.31-4.32-7.12-9.16a44.12,44.12,0,0,1-.16-6.85c0-8.45.15-12.87,5.53-16C450.39,454.33,459.84,459.57,460.39,467.12Z"
            transform="translate(-8.8 -14.5)"
          />
          <path
            className="cls-2"
            d="M369.53,457.53h20.55v2.3h-18v13.26h15.28v2.3H372.11V489h18v2.3H369.53Z"
            transform="translate(-8.8 -14.5)"
          />
          <path
            className="cls-2"
            d="M400.77,457.53h2.63l19.39,29.15h.06V457.53h2.57v33.75h-2.63L403.4,462h-.06v29.26h-2.57Z"
            transform="translate(-8.8 -14.5)"
          />
          <path
            className="cls-2"
            d="M479.35,491.28h-2.58v-14l-10-19.73v-.05h2.85l8.49,17h.06v-.11q4.19-8.45,8.38-16.88l2.74.05-10,19.73Z"
            transform="translate(-8.8 -14.5)"
          />
          <path
            className="cls-2"
            d="M70.06,457.53h5.26l12.33,33.75h-7q-1-3-2-6l-12,.06-2,5.91h-7v0Q63.93,474.39,70.06,457.53Zm21.75,0c7.24,0,15.76-.83,20,2.19s4.54,8,4.54,15.51c0,3.06,0,6-.71,8.38a12.86,12.86,0,0,1-2.52,4.33c-3.6,4.43-13.33,3.36-21.32,3.34Zm43.18,0h3.68q5.45,11.91,10.9,23.84l.11-.06,10.58-23.78h3.67v33.75h-3.62q0-12.73-.05-25.48v.11L151.11,486H148l-9.31-20.22h-.06v25.48H135Zm41.54,0h21v3.23H180.15v11.89H195v3.24H180.15v12.16h17.37v3.23h-21Zm31.35,0c6.67,0,14.9-.78,18.85,2a10.17,10.17,0,0,1,3.5,4.44c1.57,3.84,1.64,15.46.33,19.78a12.47,12.47,0,0,1-2.3,4.28c-3.46,4.29-12.65,3.3-20.38,3.28Zm34.74,0h3.62v33.75h-3.62Zm24.27,0c.68,0,2.88-.26,3.18.16l12.33,33.59-3.94,0q-1.32-3.78-2.63-7.56H261.09q-1.35,3.81-2.69,7.61h-3.89v0Q260.71,474.39,266.89,457.53Zm45.87,0,2.41.05,12.39,33.7h-2.85l-2.91-8.16H306.07q-1.44,4.08-2.9,8.16h-2.85Zm-101.27,3.23v27.29c5.56,0,10.45.44,13.54-2,3.6-2.87,3.66-18.73.71-22.52C223.21,460.25,217.32,460.74,211.49,460.76Zm102.42.28q-3.51,9.88-7,19.78H321Q317.44,470.92,313.91,461Zm-45.43,1.8-6.24,17.7h12.49v-.05Q271.61,471.67,268.48,462.84Zm-170,.61v21.92c3.61,0,7.19.24,9.16-1.32a5.32,5.32,0,0,0,1.59-2.46c1-3.34.84-13.74-.77-15.95C106.61,463.16,102.8,463.42,98.44,463.45ZM72.8,467.61l-4.17,12.11h8.28v-.05Z"
            transform="translate(-8.8 -14.5)"
          />
        </svg>
      </>
    );
  }
}
export default Logosvg;
