import React, { Component } from "react";
import "../Styles.css";
import api from "./api.js";

const initialState = {
  name: "",
  email: "",
  message: "",
  selectedBudget: "",
  selectedService: "",
  emailError: "",
  nameError: "",
  sentRequest: "",
  services: [],
};

class Project extends Component {
  state = initialState;

  validate = () => {
    if (!this.state.email.length > 0 && !this.state.email.includes("@")) {
      this.setState({ emailError: "please enter a valid email" });
    }
    if (!this.state.name.length > 0) {
      this.setState({ nameError: "please enter a name" });
    }
    this.state.email.length > 0 && this.state.name.length > 0
      ? this.setState({
          sentRequest: "Request sent !",
          nameError: "",
          emailError: "",
        })
      : this.setState({ sentRequest: "" });

    return true;
  };

  handleChangeName = (event) => {
    this.setState({
      name: event.target.value,
    });
  };
  handleChangeEmail = (event) => {
    this.setState({
      email: event.target.value,
    });
  };
  handleChangeMessage = (event) => {
    this.setState({
      message: event.target.value,
    });
  };
  handleChange = (event) => {
    this.setState({
      selectedBudget: event.target.value,
    });
  };
  handleChangeService = (event) => {
    this.setState({
      selectedService: event.target.value,
    });
  };

  submitForm = async (event) => {
    event.preventDefault();
    if (this.state.name.length > 0) {
      this.setState({ nameError: "" });
    }
    if (this.state.email.length > 0 && this.state.email.includes("@")) {
      this.setState({ emailError: "" });
    }
    let isValid = this.validate();
    if (isValid) {
      api.post("/requests", {
        name: this.state.name,
        email: this.state.email,
        message: this.state.message,
        budget: this.state.selectedBudget,
        typeOfService: this.state.selectedService,
      });
    }
  };
  componentDidMount() {
    const services = async () => {
      const { data } = await api.get("/servicecolumns");
      this.setState({ services: data.data.column });
    };
    services();
  }

  render() {
    return (
      <div
        className={` ${!this.props.show ? "hide-project" : "project-div"} ${
          this.state.sentRequest.length > 0 && "hide-overflow"
        } `}
      >
        {this.state.sentRequest.length > 0 && (
          <div className="submited-form-div">
            {" "}
            <span
              onClick={this.props.closeProject}
              className="close-span mt-5 ml-3"
              aria-hidden="true"
            >
              &times;
            </span>
            <p className="submit-message">
              Your request was submited successfully! We will be in touch soon.
            </p>
            <button onClick={this.props.closeProject} className="close-button">
              Close
            </button>
          </div>
        )}
        <div
          className={`container mb-5 ${
            this.state.sentRequest.length > 0 && "project-hide"
          }`}
        >
          <div className="col">
            <span
              onClick={this.props.closeProject}
              className="close-span"
              aria-hidden="true"
            >
              &times;
            </span>
          </div>
          <div className="row">
            <div className="col-12 text-center mt-md-5 ">
              <div>
                <h2>Need a project done?</h2>
                <h4 style={{ color: "#4B7078" }}>Tell us what you want.</h4>
              </div>
            </div>
          </div>

          <form onSubmit={this.submitForm}>
            <div className="container">
              <div className="row">
                <div className="col-md-5 ">
                  <label htmlFor="exampleInputname">Your Name *</label>
                  <input
                    value={this.state.name}
                    onChange={this.handleChangeName}
                    type="text"
                    name="name"
                    className="form-control form-control-sm"
                    placeholder="name"
                  />
                  {this.state.nameError.length > 0 && (
                    <div className="error-message">{this.state.nameError}</div>
                  )}
                </div>
                <div className="col-md-7">
                  <label>Your Email *</label>
                  <input
                    value={this.state.email}
                    onChange={this.handleChangeEmail}
                    type="email"
                    name="email"
                    className="form-control form-control-sm"
                    placeholder="enter email"
                  />
                  {this.state.emailError.length > 0 && (
                    <div className="error-message">{this.state.emailError}</div>
                  )}
                </div>
              </div>

              <div className="row  my-3">
                <div className="col-md-5 ">
                  <label className="">Your Budget</label>
                  <input
                    value={this.state.selectedBudget}
                    className="form-control form-control-sm dropdwon-input"
                    type="number"
                    onChange={this.handleChange}
                    min="1000"
                    placeholder={"minimum 1000$ "}
                  />
                  {/* <option disabled className="option-form">
                      {" "}
                    </option>
                    <option className="option-form">1000$</option>
                    <option className="option-form">1000$-5000$</option>
                    <option className="option-form">5000$-10000$</option>
                    <option className="option-form">above 10000$</option> */}
                </div>
                <div className="col-md-7">
                  <label className="">Type of Service </label>

                  <select
                    value={this.state.selectedService}
                    onChange={this.handleChangeService}
                    className="form-control form-control-sm dropdwon-input"
                  >
                    <option className="option-form">N.A</option>
                    {this.state.services.length > 0 &&
                      this.state.services.map((el) => {
                        return (
                          <option key={el._id} className="option-form">
                            {el.title}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <label>Your Message</label>
                  <textarea
                    value={this.state.message}
                    onChange={this.handleChangeMessage}
                    className="form-control"
                    rows="3"
                  ></textarea>
                </div>
              </div>
              <div className="row my-4">
                <div className="col-12">
                  <button type="submit" className="submit-button py-2">
                    Send
                  </button>
                  {this.state.sentRequest.length > 0 && (
                    <div className="sent-request text-left mt-2">
                      {this.state.sentRequest}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default Project;
